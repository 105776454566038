<template>
  <v-container fluid>
    <v-row class="pl-16">
      <v-col cols="6" class="pl-14">
        <v-row class="mt-5">
          <Menu></Menu>
          <div class="text-h3 font-weight-bold">Exames Abertos</div>
        </v-row>
        <v-row>
          <div class="light-blue-rectangle mt-3"></div>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-list-item class="mt-6 justify-end pr-16 mr-16 pt-2">
          <div class="text-center">
            <v-dialog v-model="modal"  transition="dialog-top-transition" max-width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal = true" v-bind="attrs" v-on="on">
                  <v-icon title="Filtro">mdi mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Filtros
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="justify-space-around pt-2">
                    <v-col cols="5">
                      <v-select v-model="cnpjsSelecionados" :items="cnpjs" item-text="cnpj" label="Laboratórios" multiple chips hint="Selecione os laboratórios que serão exibidos" persistent-hint></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="modal = false">
                    Pesquisar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="mt-10 pl-16">
      <v-col cols="6">
        <div class="pl-8">
          <h3 class="chart-title">Exames em aberto por classe de tempo</h3>
          <div id="examesAbertosClasseTempo"></div>
        </div>
        <div class="pl-8">
          <h3 class="chart-title">Exames em aberto por tipo de exame</h3>
          <div id="examesAbertosTipoExame"></div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="tabela pr-10" id="tabela">
          <h3 class="chart-title">Lista de exames em aberto</h3>
            <v-card class="mt-3">
              <v-row class="justify-space-around">
                <v-col cols="5">
                  <v-text-field
                      filled rounded dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Código de Exame"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-2"></v-divider>
              <v-data-table
                  :search="search"
                  :height="(this.larguraChart / 2)"
                  :headers="headers"
                  :items="examesEmAbertoLista"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  label="Itens por página:"
                  class="elevation-3"
                  @page-count="pageCount = $event"
                  no-results-text="Exame não encontrado."
                  :footer-props="{
                    'items-per-page-text':'Linhas por página'
                  }"
              ></v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>
              </div>
            </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {GoogleCharts} from "google-charts";
import api from "@/plugins/api";
import Swal from "sweetalert2";
import Menu from "@/components/Menu.vue";

export default {
  name: "ExamesAbertos",
  components: {Menu},
  data() {
    return {
      classeTempo: [],
      qtdExames: [],
      cnpjs: [],
      cnpjList: [],
      cnpjsSelecionados: [],
      examesEmAbertoLista: [],
      params: {
        cnpj: [],
        dataInicio: null,
        dataFinal: null,
      },
      headers: [
        { text: 'Cód. Exame', value: 'exame' },
        { text: 'Dias em aberto', value: 'diasAberto' },
        { text: 'Tipo de Exame', value: 'tipoExame' },
        { text: 'Data do prometido', value: 'dataPrometido',},
        { text: 'Dias de atraso', value: 'diasAtraso' },
      ],
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      larguraChart: this.larguraChart,
      modal: false,
    };
  },


  created() {
    this.listaDeLaboratorios();
    addEventListener("resize", () => {
      this.larguraChart = document.getElementById('examesAbertosClasseTempo').offsetWidth;
      this.drawChartExamesEmAbertoPorClasseTempo();
      this.drawChartExamesEmAbertoPorTipoExame();

    });
  },

  mounted() {
    this.larguraChart = document.getElementById('examesAbertosClasseTempo').offsetWidth;
  },

  watch: {
    cnpjsSelecionados: {
      handler(newValue) {
        // Verificar se o valor atual é diferente do valor armazenado
        const storedCNPJs = JSON.parse(localStorage.getItem('selectedCNPJs'));
        if (JSON.stringify(newValue) !== JSON.stringify(storedCNPJs)) {
          localStorage.setItem('selectedCNPJs', JSON.stringify(newValue));
          this.loadData();
        }
      },
    }
  },

  methods: {
    listaDeLaboratorios(){
      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
        this.primeiroCNPj = this.cnpjs[0].cnpj
        this.loadData();
      });
    },


    loadData() {
      const selectedCNPJs = localStorage.getItem('selectedCNPJs');

      if (!selectedCNPJs) {
        this.params.cnpj = [this.primeiroCNPj];
        this.cnpjsSelecionados = this.params.cnpj;
      } else {
        this.params.cnpj = JSON.parse(selectedCNPJs);
        this.cnpjsSelecionados = this.params.cnpj
      }


      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
      });

      api.post('api/examesAbertos/examesEmAbertoPorClasseDeTempo', this.params).then(async response => {
        this.examesEmAbertoCT = response.data;
        this.drawChartExamesEmAbertoPorClasseTempo();
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      });
      api.post('api/examesAbertos/examesEmAbertoPorTipoDeExame', this.params).then(response => {
        this.examesEmAbertoTE = response.data;
        this.drawChartExamesEmAbertoPorTipoExame();
      });

      api.post('api/examesAbertos/listaDeExamesEmAberto', this.params).then(response => {
        this.examesEmAbertoLista = Object.entries(response.data)[0][1]
      });
    },


    drawChartExamesEmAbertoPorClasseTempo() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Classe de Tempo');
        data.addColumn('number', 'Quantidade de exames');

        Object.keys(this.examesEmAbertoCT).forEach(classeTempo => {
          const item = this.examesEmAbertoCT[classeTempo];
          item.forEach(item => {
            data.addRow([item.classeTempo, item.qtdExames]);
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: {count: 0},
            textStyle: {color: '#b0bec5', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '12'}},
          areaOpacity: 0.24,
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height: (this.larguraChart / 3), // example height, to make the demo charts equal size
          width: this.larguraChart,
          sliceVisibilityThreshold: 0,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle: {color: 'white', style: 'bold'},
          pieHole: 0.45,
          bar: {groupWidth: "40"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions'
        };

        var chart = new GoogleCharts.api.visualization.PieChart(
            document.getElementById('examesAbertosClasseTempo')
        );
        chart.draw(data, options);
      });
    },

    drawChartExamesEmAbertoPorTipoExame() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Tipo de exame');
        data.addColumn('number', 'Quantidade de exames');

        Object.keys(this.examesEmAbertoTE).forEach(tipoExame => {
          const item = this.examesEmAbertoTE[tipoExame];
          item.forEach(item => {
            data.addRow([item.tipoExame, item.qtdExames]);
          })
        });

        const options = {
          hAxis: {
            titleTextStyle: {color: '#607d8b'},
            gridlines: {count: 0},
            textStyle: {color: '#b0bec5', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '12'}},
          areaOpacity: 0.24,
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height: (this.larguraChart / 3), // example height, to make the demo charts equal size
          width: this.larguraChart,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle: {color: 'white', style: 'bold'},
          pieHole: 0.45,
          bar: {groupWidth: "40"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: '#32373d',
          datalessRegionColor: '#37474f',
          displayMode: 'regions'
        };

        var chart = new GoogleCharts.api.visualization.PieChart(
            document.getElementById('examesAbertosTipoExame')
        );
        chart.draw(data, options);
      });
    },
  }


}

</script>


<style>
.title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
}

.chart-title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.light-blue-rectangle {
  width: 28em;
  height: 10px;
  background-color: #2196f3;
}
</style>